import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { InviteType, TenantType } from '@parashift/shared/types';
import { Tenant } from './tenant.model';
import { Permissions, UserGroups } from '@parashift/shared/constants';

export interface InvitePlainModel {
  id: string;
  accepted_at: string;
  created_at: string;
  email: string;
  permissions: Permissions[];
  rejected_at: string;
  role: string;
  tenant_id: string | number;
  token: string;
  updated_at: string;
  user_groups: UserGroups[];
  user_id: number;

  tenant: Tenant['plainModel'] | Partial<Tenant['plainModel']>;
}

@Model({ type: InviteType })
export class Invite extends BaseApiModel<InvitePlainModel> {

  @Attribute()
  accepted_at: string;

  @Attribute()
  created_at: string;

  @Attribute()
  email: string;

  @Attribute()
  permissions: Permissions[];

  @Attribute()
  rejected_at: string;

  @Attribute()
  role: string;

  @Attribute()
  tenant_id: string | number;

  @Attribute()
  token: string;

  @Attribute()
  updated_at: string;

  @Attribute()
  user_groups: UserGroups[];

  @Attribute()
  user_id: number;

  // Includes / Relations

  @BelongsTo({ class: TenantType })
  tenant: Tenant;
}
