import { Model, Attribute, BelongsTo } from './decorators';
import { BaseApiModel } from './base';
import { ApiKeyType, RoleType, TenantType, UserType } from '@parashift/shared/types';
import { ApiKey } from './api-key.model';
import { Tenant } from './tenant.model';
import { User } from './user.model';
import { UserGroups, Permissions } from '@parashift/shared/constants';

export interface RolePlainModel {
  id: string;
  created_at: string;
  permissions: Permissions[];
  role: string;
  tenant_id: number;
  updated_at: string;
  user_groups: UserGroups[];
  user_id: number;

  user: User['plainModel'] | Partial<User['plainModel']>;
  tenant: Tenant['plainModel'] | Partial<Tenant['plainModel']>;
  api_key: ApiKey['plainModel'] | Partial<ApiKey['plainModel']>;
}

@Model({ type: RoleType })
export class Role extends BaseApiModel<RolePlainModel> {

  @Attribute()
  created_at: string;

  @Attribute()
  permissions: Permissions[];

  @Attribute()
  role: string;

  @Attribute()
  tenant_id: number;

  @Attribute()
  updated_at: string;

  @Attribute()
  user_groups: UserGroups[];

  @Attribute()
  user_id: number;

  // Includes / Relations

  @BelongsTo({ class: UserType })
  user: User;

  @BelongsTo({ class: TenantType })
  tenant: Tenant;

  @BelongsTo({ class: ApiKeyType })
  api_key: ApiKey;
}
